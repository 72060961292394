import React, { useEffect, useState } from 'react'

import Upload from '../Shared/Upload'
import { PostMergeSlikUpload } from '../../utils/api'
import { useAnalyticsPushEvent } from '../../analytics'
import MergeSlikContainer from './MergeSlikContainer'

function App () {
    const pushEvent = useAnalyticsPushEvent()
    const [triggerRefreshTable, setTriggerRefreshTable] = useState(null)

    // Upload.
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('SLIK picked', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }

    const uploadFileUploaded = (file) => {
        setTriggerRefreshTable(new Date().getTime(triggerRefreshTable))

        pushEvent('SLIK uploaded', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            }
        })
    }

    // Inits.
    useEffect(() => {
        pushEvent('List SLIK')
    }, [pushEvent])

    return (
        <>
            <h2>Sistem Layanan Informasi Keuangan (SLIK)</h2>
            <div className="box-shadow-container mb-4">
                <h4 className="mb-3">Unggah File</h4>
                <Upload
                    uploadUrl={PostMergeSlikUpload()}
                    filePicked={uploadFilePicked}
                    fileUploaded={uploadFileUploaded}
                    showNotes
                />
            </div>
            <div className="box-shadow-container mb-3">
                <h4 className="mb-3">Daftar Sistem Layanan Informasi Keuangan</h4>
                <MergeSlikContainer triggerRefreshTable={triggerRefreshTable} />
            </div>
        </>
    )
}

export default App
