import React, { useState } from 'react'
const AuthContext = React.createContext()

const initializeData = {
    sp: '', // sp is property to set background process on statement detail page
    windowDressingFilters: [],
    supplierFilters: [],
    buyerFilters: [],
    type: ''
}

const StatementProvider = ({ children }) => {
    const [data, setData] = useState(initializeData)

    const setStatementData = (newData) => {
        setData((prev) => ({
            ...prev,
            ...newData
        }))
    }

    const resetStatementData = () => {
        setData({
            sp: '',
            windowDressingFilters: [],
            supplierFilters: [],
            buyerFilters: [],
            type: ''
        })
    }

    const value = {
        statementData: data,
        setStatementData,
        resetStatementData
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export { StatementProvider }
export default AuthContext
