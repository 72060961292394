import React from 'react'

import { Row, Col, Table } from 'react-bootstrap'

import './statement.css'

const App = (props) => {
    const { mergeStatement, detailStatements } = props

    return (
        <>
            { mergeStatement?.analysis_status === 'completed' && (
                <div className='box-shadow-container mb-4'>
                    <Row className="mb-3">
                        <Col>
                            <h4>Daftar Rekening Koran</h4>
                        </Col>
                    </Row>
                    {detailStatements.map((itemStatement, index) => (
                        <div className="item rounded border border-secondary-subtle dashed w-100 pt-4 mb-3" key={itemStatement.uuid}>
                            <Row>
                                <Col xs={1} className="d-flex justify-content-center align-items-center">
                                    <p className="text-uppercase fw-bold">{index + 1}</p>
                                </Col>
                                <Col xs={8} className="border-start px-4">
                                    <Row>
                                        <Col xs={2}>
                                            <p className='fw-bold fs-16'>Nama File</p>
                                        </Col>
                                        <Col xs={10} className="text-left">
                                            <a href={itemStatement.file_url} target="_blank" className="text-break fs-16" rel="noreferrer">
                                                {itemStatement.file_name}
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {itemStatement.detail_statements.length > 0 && (
                                <>
                                    <hr className="my-3" />
                                    <div className="mx-3">
                                        <Table responsive striped hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '1%' }}>#</th>
                                                    <th style={{ width: '300px' }}>Bank</th>
                                                    <th>Nama Rekening</th>
                                                    <th style={{ width: '220px' }}>Nomor Rekening</th>
                                                    <th style={{ width: '100px' }}>Periode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemStatement.detail_statements?.map((detail, subIndex) => (
                                                        <tr key={subIndex}>
                                                            <td>{subIndex + 1}</td>
                                                            <td className="text-nowrap">{detail.bank_name}</td>
                                                            <td>{detail.bank_account_name}</td>
                                                            <td className="text-nowrap">{detail.bank_account_number}</td>
                                                            <td className="text-nowrap">{detail.period}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default App
