import React, { useEffect, useState } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import LottieAnimation from '../Shared/LottieAnimation'
import LottieAnimation1 from '../Shared/LottieAnimation1.json'

import './statement.css'

const LoadingComponent = ({ labelProgress }) => (
    <Row className="text-center">
        <Col md={{ span: 6, offset: 3 }}>
            <LottieAnimation animationData={LottieAnimation1} />
        </Col>
        <Col md={12}>
            <h5>Memproses semua dokumen...</h5>
            {
                labelProgress >= 99
                    ? <h5>Kami sedang menyiapkan semuanya untuk Anda!</h5>
                    : <h5>Dokumen sedang diekstraksi dan dianalisa</h5>
            }

        </Col>
    </Row>
)

const App = (props) => {
    const {
        mergeStatement
    } = props

    const REFRESH_RATE_IN_MILLISECOND = 500
    const SECOND_PER_FILE = 60
    const COUNTER_PER_PROGRESS = SECOND_PER_FILE * (1000 / REFRESH_RATE_IN_MILLISECOND)

    const [timeoutAnimation, setTimeoutAnimation] = useState(0)
    const [processingProgress, setProcessingProgress] = useState(0)
    const [targetProgress, setTargetProgress] = useState(100)
    const [targetProgressPerFile, setTargetProgressPerFile] = useState(0)

    useEffect(() => {
        const fn = (ms) => {
            if (!ms) return

            const totalFiles = ms.total_all_statements || 1
            const processedFiles = ms.total_processed_statements || 0
            if (totalFiles === processedFiles) {
                setProcessingProgress(100)
                setTargetProgress(100)

                // Stops the animation after 1 second (not actually used, but to be safe).
                setTimeoutAnimation(1000)
            } else {
                const totalFilesWithMerge = totalFiles + 1
                const targetProgressValue = (totalFilesWithMerge) * COUNTER_PER_PROGRESS
                setProcessingProgress(processedFiles * COUNTER_PER_PROGRESS)
                setTargetProgress(targetProgressValue)
                setTargetProgressPerFile(Math.round(targetProgressValue / totalFilesWithMerge) * (processedFiles + 1))

                // Stops the animation after 121 second for example (1 file + 1 merge process).
                setTimeoutAnimation(((totalFiles - processedFiles + 1) * SECOND_PER_FILE * 1000) + 1000)
            }
        }

        fn(mergeStatement)
    }, [mergeStatement])

    useEffect(() => {
        let intervalId
        let timeoutId
        if (processingProgress < targetProgress) {
            intervalId = setInterval(() => {
                setProcessingProgress((prev) => {
                    if (prev >= targetProgressPerFile) {
                        return targetProgressPerFile
                    }

                    return prev + 1
                })
            }, REFRESH_RATE_IN_MILLISECOND)

            timeoutId = setTimeout(() => {
                clearInterval(intervalId)
                setProcessingProgress(targetProgress)
            }, timeoutAnimation)
        }

        return () => {
            try {
                if (intervalId) {
                    clearInterval(intervalId)
                }

                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
            } catch (err) {
                console.log(err)
            }
        }
    }, [targetProgress, timeoutAnimation, targetProgressPerFile])

    const labelProgress = targetProgressPerFile >= targetProgress ? 99 : Math.floor((processingProgress * 100) / targetProgress)
    return (
        <>
            <LoadingComponent labelProgress={labelProgress}/>
            <div className="position-relative w-100 mt-3 mb-3">
                <ProgressBar
                    animated
                    now={processingProgress}
                    max={targetProgress}
                    style={{ height: '25px' }}
                />
                <div className={`position-absolute top-50 start-50 translate-middle fs-5 fw-bold ${labelProgress < 50 ? 'text-dark' : 'text-white'}`}>
                    {labelProgress}%
                </div>
                {/* <p>processingProgress {processingProgress}</p>
            <p>targetProgress {targetProgress}</p>
            <p>timeoutAnimation {timeoutAnimation}</p> */}
            </div>
        </>
    )
}

export default App
