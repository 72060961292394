import Axios from 'axios'
import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'

import CustomModal from '../../Shared/CustomModal'

import { useAnalyticsPushEvent } from '../../../analytics'
import NotificationContext from '../../../contexts/notification'
import { GetApplicantDetail, GetApplicantIncomeDetail, PostApplicantIncomeVerification } from '../../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onProcessItem }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()

    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [incomeData, setIncomeData] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleAction = () => {
        if (!selected) return

        async function fetchData () {
            setIsSubmitting(true)
            try {
                const { data: responseData } = await Axios.post(PostApplicantIncomeVerification(selected.uuid))
                pushNotification('success', responseData.message)
                setShowModal(false)

                if (onProcessItem && typeof onProcessItem === 'function') {
                    onProcessItem('Applicant Income Verification Process', selected)
                }
            } catch (err) {
                pushNotification('error', null, err)
            } finally {
                setIsSubmitting(false)
            }
        }

        fetchData()
    }

    async function fetchDetail (item) {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetApplicantDetail(item.uuid))
            setSelected((prev) => ({
                ...prev,
                identity_number: responseData.data?.identity_number,
                name: responseData.data?.name,
                identity_url: responseData.data?.identity_url
            }))
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    async function fetchIncomeVerificationResponseMessage (item) {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetApplicantIncomeDetail(item.uuid))
            setIncomeData(responseData.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        if (!selectedItem) return

        setSelected(selectedItem)

        if (selectedItem.type_modal === 'view_detail') {
            // Get income information from API.
            fetchIncomeVerificationResponseMessage(selectedItem)

            // Gets non mask information from API.
            fetchDetail(selectedItem)
        } else {
            // Gets non mask information from API.
            fetchDetail(selectedItem)
        }

        // Adds to amplitude.
        pushEvent('Applicant Income Verification Process', { clickedItem: selectedItem })
    }, [selectedItem])

    return (
        <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Verifikasi Pendapatan"
            primaryButtonText={selected?.type_modal === 'view_detail' ? '' : 'Konfirmasi'}
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            secondaryButtonText="Batal"
            primaryButtonDisabled={isSubmitting || loading}
            secondaryButtonDisabled={isSubmitting}
            loading={isSubmitting}
        >
            {
                loading && (
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                )
            }
            {
                !loading && (
                    <>
                        {
                            selected?.type_modal !== 'view_detail'
                                ? <>
                                    Apakah Anda yakin ingin melakukan Verifikasi Pendapatan untuk data berikut?
                                    <div className="mt-3">
                                        <Row className="mb-3">
                                            <Col sm="2"><strong>KTP</strong></Col>
                                            <Col sm="10"><h5>{selected?.identity_number}</h5></Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm="2"><strong>Nama</strong></Col>
                                            <Col sm="10"><h5>{selected?.name}</h5></Col>
                                        </Row>
                                    </div>
                                </>
                                : <>
                                    {
                                        incomeData?.message
                                            ? <span>{incomeData?.message}</span>
                                            : <div className="p-3 border-0 rounded-3">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <h5 className=" mb-1">
                                                    Nama :{' '}
                                                                {selected?.name}
                                                            </h5>
                                                            <p className="text-muted mb-1">
                                                    Nomor KTP :{' '}
                                                                {selected?.identity_number}
                                                            </p>
                                                            <p className="text-muted mb-1">
                                                    Pendapatan :{' '}
                                                                {incomeData?.income}
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                    }

                                </>
                        }
                    </>
                )
            }
        </CustomModal>
    )
}

export default App
