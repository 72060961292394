import React, { useContext, useEffect, useState } from 'react'

import Axios from 'axios'
import { Row, Col, Button } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import StatementDetailContainer from './StatementDetailContainer'
import { useSocketHandlers, useAutoRefresh } from './StatementDetailUseEffect'

import NotificationContext from '../../contexts/notification'
import {
    GetMergeStatementDetail,
    GetStatementList
} from '../../utils/api'

function App ({ type }) {
    const { pushNotification } = useContext(NotificationContext)
    const [loading, setLoading] = useState(false)

    // Processed files statement.
    const { uuid } = useParams()
    const [mergeStatement, setMergeStatement] = useState(null)
    const [detailStatements, setDetailStatements] = useState([])

    async function fetchFilesStatement (setLoadingState = true) {
        try {
            if (setLoadingState) {
                setLoading(true)
            }

            const { data: detailResponseData } = await Axios.get(GetMergeStatementDetail(uuid))
            const ms = detailResponseData?.data
            setMergeStatement(ms)

            // Only retrieves list statements when it's done.
            if (ms.analysis_status === 'completed') {
                const statementListParams = { merge_uuid: uuid }
                const { data: listResponseData } = await Axios.get(GetStatementList(), { params: statementListParams })
                setDetailStatements(listResponseData?.data)
            }
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        // console.log('uuid', uuid)
        fetchFilesStatement()
    }, [uuid])

    useSocketHandlers({
        mergeStatement,
        setMergeStatement,
        fetchFilesStatement
    })
    useAutoRefresh({
        mergeStatement,
        fetchFilesStatement
    })

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <h2>Rekening Koran {type === 'sme' ? '(Produktif)' : '(Konsumtif)'}</h2>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <Button variant="link" className="text-decoration-none" as={Link} to={`/${type === 'sme' ? 'statement' : 'consumptive-statement'}`}>
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />Kembali
                    </Button>
                </Col>
            </Row>
            { mergeStatement && (
                <StatementDetailContainer
                    type={type}
                    mergeStatement={mergeStatement}
                    detailStatements={detailStatements}
                    loading={loading}
                />
            )}
        </>
    )
}

export default App
