import React from 'react'

import StatementDetailInformation from './StatementDetailInformation'
import StatementDetailFile from './StatementDetailFile'
import StatementDetailListStatement from './StatementDetailListStatement'

import './statement.css'

const App = (props) => {
    const {
        type,
        mergeStatement,
        detailStatements,
        loading
    } = props

    return (
        <>
            <StatementDetailInformation
                type={type}
                mergeStatement={mergeStatement}
                loading={loading}
            />
            <StatementDetailFile
                mergeStatement={mergeStatement}
                loading={loading}
            />
            <StatementDetailListStatement
                mergeStatement={mergeStatement}
                detailStatements={detailStatements}
            />
        </>

    )
}

export default App
