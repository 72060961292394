import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import MergePefindoDeleteModal from './MergePefindoDeleteModal'
import MergePefindoProcessModal from './MergePefindoProcessModal'
import MergePefindoShowResultModal from './MergePefindoShowResultModal'
import MergePefindoTableData from './MergePefindoTableData'
import TablePagination from '../Shared/TablePagination'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetMergePefindoList } from '../../utils/api'

function App ({ triggerRefreshTable }) {
    // Gets default value from query params.
    const defaultParams = { page: 1, limit: 20 }
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
        defaultParams.page = page
    }

    const { pushNotification } = useContext(NotificationContext)
    const [loading, setLoading] = useState(true)
    const [listParams, setListParams] = useState(defaultParams)
    const [data, setData] = useState([])
    async function fetchMergePefindo () {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetMergePefindoList(), { params: { ...listParams } })
            setData(responseData)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const pushEvent = useAnalyticsPushEvent()

    // Show result pefindo.
    const [showModalShowResultPefindo, setShowModalShowResultPefindo] = useState(false)
    const [selectedShowResultPefindo, setSelectedShowResultPefindo] = useState(null)

    // Process pefindo.
    const [showModalProcessedPefindo, setShowModalProcessedPefindo] = useState(false)
    const [selectedProcessedPefindo, setSelectedProcessedPefindo] = useState(null)

    // Delete pefindo.
    const [showModalDeletedPefindo, setShowModalDeletedPefindo] = useState(false)
    const [selectedDeletedPefindo, setSelectedDeletedPefindo] = useState(null)

    const onDeleted = (deleted) => {
        setSelectedShowResultPefindo(null)
        setSelectedProcessedPefindo(null)
        setSelectedDeletedPefindo(null)
        fetchMergePefindo()

        // Adds to amplitude.
        pushEvent('Pefindo Folder deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        fetchMergePefindo()
    }, [listParams])
    useEffect(() => {
        if (triggerRefreshTable) {
            setListParams((prev) => ({ ...prev, page: 1 }))
        }
    }, [triggerRefreshTable])

    return (
        <>
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />
            <MergePefindoTableData
                lading={loading}
                listParams={listParams} listItems={data?.data}
                setShowModalShowResultPefindo={setShowModalShowResultPefindo}
                setSelectedShowResultPefindo={setSelectedShowResultPefindo}
                setShowModalProcessedPefindo={setShowModalProcessedPefindo}
                setSelectedProcessedPefindo={setSelectedProcessedPefindo}
                setShowModalDeletedPefindo={setShowModalDeletedPefindo}
                setSelectedDeletedPefindo={setSelectedDeletedPefindo}
            />
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />

            <MergePefindoShowResultModal
                showModal={showModalShowResultPefindo}
                setShowModal={setShowModalShowResultPefindo}
                selectedItem={selectedShowResultPefindo}>
            </MergePefindoShowResultModal>

            <MergePefindoProcessModal
                showModal={showModalProcessedPefindo}
                setShowModal={setShowModalProcessedPefindo}
                selectedItem={selectedProcessedPefindo}
                onProcessCompleted={fetchMergePefindo}>
            </MergePefindoProcessModal>

            <MergePefindoDeleteModal
                showModal={showModalDeletedPefindo}
                setShowModal={setShowModalDeletedPefindo}
                selectedItem={selectedDeletedPefindo}
                onDeleted={onDeleted}>
            </MergePefindoDeleteModal>
        </>
    )
}

export default App
