import { useContext, useEffect } from 'react'

import Axios from 'axios'
import NotificationContext from '../../contexts/notification'
import SocketContext from '../../contexts/socket'
import {
    GetMergeStatementDetail
} from '../../utils/api'

export const useSocketHandlers = (props) => {
    const {
        data,
        setData
    } = props

    const { subscribe, unsubscribe, events } = useContext(SocketContext)

    const notificationDocumentCompletedStatusCompletedHandler = (socketData, tableData) => {
        if (!tableData?.data?.length) return

        const foundMs = tableData?.data?.find((ms) => ms.uuid === socketData.doc.uuid)
        if (!foundMs) return

        setData((prev) => {
            const newList = prev.data?.map((item) => {
                if (foundMs.uuid === item.uuid) {
                    return {
                        ...item,
                        analysis_status: 'completed',
                        processed_at: socketData?.doc?.processed_at,
                        completed_at: socketData?.doc?.completed_at,
                        bank_statement_detail: socketData?.doc?.bank_statement_detail
                    }
                }

                return item
            })
            return {
                ...prev,
                data: newList
            }
        })
    }

    const notificationDocumentCompletedStatusUnprocessedHandler = (socketData, tableData) => {
        if (!tableData?.data?.length) return

        const foundMs = tableData?.data?.find((ms) => ms.uuid === socketData.doc.uuid)
        if (!foundMs) return

        setData((prev) => {
            const newList = prev.data?.map((item) => {
                if (foundMs.uuid === item.uuid) {
                    return {
                        ...item,
                        analysis_status: 'unprocessed'
                    }
                }

                return item
            })
            return {
                ...prev,
                data: newList
            }
        })
    }

    // Socket handler.
    const notificationDocumentCompletedHandler = async (socketData, tableData) => {
        try {
            if (socketData?.doc_type !== 'bs') return
            if (!socketData?.doc?.uuid) return
            if (socketData?.status === 'completed') {
                notificationDocumentCompletedStatusCompletedHandler(socketData, tableData)
            } else if (socketData?.status === 'unprocessed') {
                notificationDocumentCompletedStatusUnprocessedHandler(socketData, tableData)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        subscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler, data)
        return () => {
            unsubscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler)
        }
    }, [data])

    return {}
}

export const useAutoRefresh = (props) => {
    const {
        data,
        setData
    } = props

    const { pushNotification } = useContext(NotificationContext)

    const fetchMergeStatement = async (uuid) => {
        try {
            const { data: detailResponseData } = await Axios.get(GetMergeStatementDetail(uuid))
            const ms = detailResponseData?.data
            if (!ms) return

            setData((prev) => {
                const newList = prev.data?.map((item) => {
                    if (ms.uuid === item.uuid) {
                        return {
                            ...item,
                            analysis_status: ms.analysis_status,
                            bank_statement_detail: ms.bank_statement_detail,
                            processed_at: ms.processed_at,
                            completed_at: ms.completed_at,
                            fine_tuning_estimation_at: ms.fine_tuning_estimation_at
                        }
                    }

                    return item
                })
                return {
                    ...prev,
                    data: newList
                }
            })
        } catch (err) {
            pushNotification('error', null, err)
        }
    }

    useEffect(() => {
        if (!data?.data?.length) return
        const foundData = data.data.filter((ms) => ms.analysis_status === 'in_progress' && ms.fine_tuning_estimation_at)
        if (!foundData.length) return

        const items = foundData
            .map((ms) => {
                const targetTime = new Date(ms.fine_tuning_estimation_at).getTime()
                const timeDifference = targetTime - Date.now()
                return {
                    uuid: ms.uuid,
                    timeDifference
                }
            })
            .filter((x) => x.timeDifference >= 0)
        if (items.length === 0) return
        // console.log('useAutoRefresh items', items, new Date())

        items.sort((a, b) => a.timeDifference - b.timeDifference)
        const soonestItem = items[0]
        if (!soonestItem) return
        // console.log('useAutoRefresh soonestItem', soonestItem, new Date())

        const uuid = soonestItem.uuid
        const timeDifference = soonestItem.timeDifference
        if (timeDifference < 0) return
        // console.log('useAutoRefresh timeDifference', timeDifference, new Date())

        const timeoutId = setTimeout(() => {
            // console.log('useAutoRefresh setTimeout', new Date())
            fetchMergeStatement(uuid)
        }, timeDifference)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [data])

    return {}
}
