import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import MergeStatementDeleteModal from './MergeStatementDeleteModal'
import MergeStatementProcessModal from './MergeStatementProcessModal'
import MergeStatementTableData from './MergeStatementTableData'
import TablePagination from '../Shared/TablePagination'
import { useSocketHandlers, useAutoRefresh } from '../SharedStatement/MergeStatementListUseEffect'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetMergeStatementList } from '../../utils/api'

function App ({ triggerRefreshTable }) {
    // Gets default value from query params.
    const defaultParams = { page: 1, limit: 20 }
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
        defaultParams.page = page
    }

    const { pushNotification } = useContext(NotificationContext)
    const [loading, setLoading] = useState(true)
    const [listParams, setListParams] = useState(defaultParams)
    const [data, setData] = useState([])
    async function fetchMergeStatement () {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetMergeStatementList(), { params: { ...listParams, type: 'consumptive' } })
            setData(responseData)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const pushEvent = useAnalyticsPushEvent()

    // Process statement.
    const [showModalProcessedStatement, setShowModalProcessedStatement] = useState(false)
    const [selectedProcessedStatement, setSelectedProcessedStatement] = useState(null)

    // Delete statement.
    const [showModalDeletedStatement, setShowModalDeletedStatement] = useState(false)
    const [selectedDeletedStatement, setSelectedDeletedStatement] = useState(null)

    const onDeleted = (deleted) => {
        setSelectedProcessedStatement(null)
        setSelectedDeletedStatement(null)
        fetchMergeStatement()

        // Adds to amplitude.
        pushEvent('Statement Folder deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        fetchMergeStatement()
    }, [listParams])
    useEffect(() => {
        if (triggerRefreshTable) {
            setListParams((prev) => ({ ...prev, page: 1 }))
        }
    }, [triggerRefreshTable])

    useSocketHandlers({
        data,
        setData
    })
    useAutoRefresh({
        data,
        setData
    })

    return (
        <>
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />
            <MergeStatementTableData
                loading={loading}
                mergeStatementData={data}
                listParams={listParams} listItems={data?.data}
                setShowModalProcessedStatement={setShowModalProcessedStatement}
                setSelectedProcessedStatement={setSelectedProcessedStatement}
                setShowModalDeletedStatement={setShowModalDeletedStatement}
                setSelectedDeletedStatement={setSelectedDeletedStatement}
            />
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />

            <MergeStatementProcessModal
                showModal={showModalProcessedStatement}
                setShowModal={setShowModalProcessedStatement}
                selectedItem={selectedProcessedStatement}
                onProcessCompleted={fetchMergeStatement}>
            </MergeStatementProcessModal>

            <MergeStatementDeleteModal
                showModal={showModalDeletedStatement}
                setShowModal={setShowModalDeletedStatement}
                selectedItem={selectedDeletedStatement}
                onDeleted={onDeleted}>
            </MergeStatementDeleteModal>
        </>
    )
}

export default App
