import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import StatementTableData from './StatementTableData'
import { useAnalyticsPushEvent } from '../../analytics'
import StatementDeleteModal from './StatementDeleteModal'

const App = forwardRef(({ mergeStatement, statements, fetchFilesStatement, processing }, ref) => {
    const pushEvent = useAnalyticsPushEvent()

    const handlePreviewUploaded = (item) => {
        pushEvent('Preview Uploaded File', {
            clickedItem: item
        })
        window.open(item.file_url)
    }

    // Delete statement.
    const [showModalDeletedStatement, setShowModalDeletedStatement] = useState(false)
    const [selectedDeletedStatement, setSelectedDeletedStatement] = useState(null)

    // Passwords.
    const [statementPasswords, setStatementPasswords] = useState({})
    const [statementErrorFilesMessage, setStatementErrorFilesMessage] = useState([])
    const handleChangeStatementPassword = (uuid, value) => {
        const newStatementPasswords = { ...statementPasswords }
        newStatementPasswords[uuid] = value
        setStatementPasswords(newStatementPasswords)
    }

    const onStatementDeleted = (deleted) => {
        fetchFilesStatement(mergeStatement)

        // Adds to amplitude.
        pushEvent('Statement File deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        const maps = {}
        statements.forEach(x => {
            maps[x.uuid] = x.password || ''
        })
        setStatementPasswords(maps)
    }, [statements])

    useImperativeHandle(ref, () => ({
        getStatementPasswords: () => { return statementPasswords },
        setStatementErrorFilesMessageTable: (objectError, isResetErrorMessage = false) => {
            return new Promise((resolve) => {
                if (isResetErrorMessage) {
                    setStatementErrorFilesMessage([])
                    resolve()
                } else {
                    setStatementErrorFilesMessage((prevState) => {
                        const isObjectInState = prevState.some(
                            (item) => item.uuid === objectError.uuid
                        )

                        const updatedState = isObjectInState
                            ? prevState.map((item) => (item.uuid === objectError.uuid ? objectError : item))
                            : [...prevState, objectError]

                        resolve(updatedState) // Resolve after updating state
                        return updatedState
                    })
                }
            })
        },
        getStatementErrorFiles: () => { return statementErrorFilesMessage }
    }))

    return (
        <>
            <StatementTableData
                mergeStatement={mergeStatement} statements={statements}
                statementPasswords={statementPasswords} handleChangeStatementPassword={handleChangeStatementPassword}
                processing={processing} handlePreviewUploaded={handlePreviewUploaded}
                setShowModalDeletedStatement={setShowModalDeletedStatement}
                setSelectedDeletedStatement={setSelectedDeletedStatement}
                statementErrorFilesMessage={statementErrorFilesMessage}
            />

            <StatementDeleteModal
                showModal={showModalDeletedStatement}
                setShowModal={setShowModalDeletedStatement}
                selectedItem={selectedDeletedStatement}
                onFileDeleted={onStatementDeleted}>
            </StatementDeleteModal>
        </>
    )
})

export default App
