import React, { useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import { getCookie } from '../utils/cookie'

const EVENTS = {
    NOTIFICATION_DOCUMENT_COMPLETED_EVENT: 'notification-document-completed',
    NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT: 'notification-statement-file-completed'
}

const SocketContext = React.createContext()
const SocketProvider = ({ children }) => {
    const socket = useRef(null)
    const pubSubEvents = useRef({})

    // Subscribes to an event.
    const subscribe = (eventName, callback, ...params) => {
        if (!pubSubEvents.current[eventName]) {
            pubSubEvents.current[eventName] = []
        }
        // Store the callback along with additional parameters.
        pubSubEvents.current[eventName].push({ callback, params })
    }

    // Unsubscribes from an event.
    const unsubscribe = (eventName, callback) => {
        if (pubSubEvents.current[eventName]) {
            pubSubEvents.current[eventName] = pubSubEvents.current[eventName].filter((entry) => entry.callback !== callback)
        }
    }

    // Publishes an event.
    const publish = (eventName, data) => {
        if (pubSubEvents.current[eventName]) {
            pubSubEvents.current[eventName].forEach(({ callback, params }) => {
                // Pass the data and additional parameters to the callback.
                callback(data, ...params)
            })
        }
    }

    // Socket handler.
    const notificationDocumentCompletedHandler = async (data) => {
        try {
            // Propagates.
            publish(EVENTS.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, data)
        } catch (err) {
            console.log(err)
        }
    }

    // Socket handler.
    const notificationStatementFileCompletedHandler = async (data) => {
        try {
            // Propagates.
            publish(EVENTS.NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT, data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        try {
            // Validates.
            const token = getCookie()
            if (!token) return

            // Inits socket.
            socket.current = io(process.env.REACT_APP_APP_SOCKET_URL, {
                transports: ['websocket'],
                auth: { token }
            })

            socket.current.on(EVENTS.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler)
            socket.current.on(EVENTS.NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT, notificationStatementFileCompletedHandler)
            // eslint-disable-next-line no-empty
        } catch (_) {
        }

        // Cleans up socket connection on unmount.
        return () => {
            try {
                if (!socket.current) return

                socket.current.off(EVENTS.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler)
                socket.current.off(EVENTS.NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT, notificationStatementFileCompletedHandler)
                socket.current.disconnect()
                // eslint-disable-next-line no-empty
            } catch (_) {
            }
        }
    }, [location.pathname])

    return (
        <SocketContext.Provider
            value={{
                subscribe,
                unsubscribe,
                events: EVENTS
            }}
        >
            {children}
        </SocketContext.Provider>
    )
}

export { SocketProvider }
export default SocketContext
