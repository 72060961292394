import React, { useState } from 'react'

import { Button, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheckCircle as iconChecked, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import CustomInput from '../Shared/CustomInput'

const App = (props) => {
    const [listShowPassword, setListShowPassword] = useState([])

    const {
        mergePefindo, pefindos,
        pefindoPasswords, handleChangePefindoPassword,
        processing, handlePreviewUploaded,
        setShowModalDeletedPefindo,
        setSelectedDeletedPefindo
    } = props

    const onDeleteClicked = (item) => {
        setSelectedDeletedPefindo({ ...item })
        setShowModalDeletedPefindo(true)
    }

    return (
        <Table responsive striped hover className="mt-3">
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th>File</th>
                    <th>Password</th>
                    <th style={{ width: '170px' }}>Dibuat</th>
                    <th style={{ width: '75px' }}></th>
                </tr>
            </thead>
            <tbody>
                {pefindos?.map((item, index) => (
                    <tr key={item.uuid}>
                        <td>{index + 1}</td>
                        <td>
                            <a
                                href={item.file_url}
                                className="link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
                                onClick={(e) => {
                                    e.preventDefault()
                                    handlePreviewUploaded(item)
                                }}
                            >
                                {item.file_name}
                            </a>
                        </td>
                        <td>
                            {
                                (!mergePefindo?.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                (
                                    <div>
                                        <CustomInput
                                            name="password"
                                            placeholder="password"
                                            type={listShowPassword.find(val => val.uuid === item.uuid)?.showPassword ? 'text' : 'password'}
                                            value={pefindoPasswords?.[item.uuid] || ''} disabled={processing}
                                            required={true}
                                            autoComplete="off"
                                            onChange={(e) => handleChangePefindoPassword(item.uuid, e.target.value)}
                                            icon={<FontAwesomeIcon icon={listShowPassword.find(val => val.uuid === item.uuid)?.showPassword ? faEyeSlash : faEye} />}
                                            clickableIcon={!processing}
                                            onIconClick={() => {
                                                if (listShowPassword.find(val => val.uuid === item.uuid)) {
                                                    setListShowPassword(prev =>
                                                        prev.map(val =>
                                                            val.uuid === item.uuid
                                                                ? { ...val, showPassword: !val.showPassword }
                                                                : val
                                                        )
                                                    )
                                                } else {
                                                    setListShowPassword((prev) => [
                                                        ...prev,
                                                        {
                                                            uuid: item.uuid,
                                                            showPassword: true
                                                        }
                                                    ])
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </td>
                        <td>{item.created_at}</td>
                        <td>
                            {
                                (!mergePefindo?.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                    (<Button size="sm" variant="link" className="text-decoration-none"
                                        onClick={() => onDeleteClicked(item)}
                                        disabled={processing}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" className="text-danger" />
                                    </Button>)
                            }
                            {
                                (!!item.processed_at) &&
                                    (<FontAwesomeIcon icon={iconChecked} size="lg" className="text-success" />)
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default App
