import React from 'react'

import { Button, Spinner, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faExternalLink as ShowResultIcon, faTrash } from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const {
        loading,
        listParams, listItems,
        setShowModalShowResultSlik,
        setSelectedShowResultSlik,
        setShowModalProcessedSlik,
        setSelectedProcessedSlik,
        setShowModalDeletedSlik,
        setSelectedDeletedSlik
    } = props

    const onShowResultClicked = (item) => {
        setSelectedShowResultSlik({ ...item })
        setShowModalShowResultSlik(true)
    }

    const onProcessClicked = (item) => {
        setSelectedProcessedSlik({ ...item })
        setShowModalProcessedSlik(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedSlik({ ...item })
        setShowModalDeletedSlik(true)
    }

    return (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th>File</th>
                    <th style={{ width: '40%' }}>Informasi</th>
                    <th style={{ width: '100px', minWidth: '80px' }} className="text-center">Hasil</th>
                    <th style={{ width: '100px', minWidth: '100px' }}>Dibuat</th>
                    <th style={{ width: '100px', minWidth: '100px' }}>Diproses</th>
                    <th style={{ width: '1%' }} colSpan={3} className="text-center">Tindakan</th>
                </tr>
            </thead>
            { loading && (
                <tbody>
                    <tr>
                        <td colSpan="100" className="text-center p-4">
                            <Spinner animation="border" variant="primary" />
                        </td>
                    </tr>
                </tbody>
            )}
            { !loading && (
                <tbody>
                    {listItems?.map((item, index) => (
                        <tr key={item.uuid}>
                            <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                            <td><pre style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>{item.file_name || item.uuid}</pre></td>
                            <td style={{ whiteSpace: 'break-spaces' }}>
                                {
                                    item && item.slik_detail
                                        ? item.slik_detail.map((detail, index) => {
                                            return `${index + 1}. ${detail.borrower_name}`
                                        }).join('\n')
                                        : null
                                }
                            </td>
                            <td style={{ width: '115px' }} className="text-center">
                                {
                                    (item.processed_at) &&
                                    (<Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onShowResultClicked(item)}>
                                        <FontAwesomeIcon icon={ShowResultIcon} size="lg" />
                                        <p className="m-1">Lihat Hasil</p>
                                    </Button>)
                                }
                            </td>
                            <td>{item.processed_at}</td>
                            <td>{item.created_at}</td>
                            <td className="text-center">
                                {
                                    (!item.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                    (<Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onProcessClicked(item)}>
                                        <FontAwesomeIcon icon={faCog} size="lg" />
                                        <p className="m-1">Proses</p>
                                    </Button>)
                                }
                            </td>
                            <td className="text-center">
                                <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                    onClick={() => onDeleteClicked(item)}>
                                    <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                    <p className="m-1">Hapus</p>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            )}
        </Table >
    )
}

export default App
