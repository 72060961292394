/* eslint-disable no-unused-vars */
import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Spinner, Row, Col, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import MergeApplicantIncomeVerificationModal from './ApplicantIncomeVerificationModal'
import MergeApplicantDeleteModal from './ApplicantDeleteModal'
import MergeApplicantEKYCModal from './ApplicantEKYCModal'
import MergeApplicantFamilyCertificateModal from './ApplicantFamilyCertificateModal'
import MergeApplicantTableData from './ApplicantTableData'
import TablePagination from '../../Shared/TablePagination'

import { useAnalyticsPushEvent } from '../../../analytics'
import NotificationContext from '../../../contexts/notification'
import { GetApplicantList, GetApplicantVerificationStatus } from '../../../utils/api'

function App ({ triggerRefreshTable }) {
    // Gets default value from query params.
    const defaultParams = { page: 1, limit: 20 }
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
        defaultParams.page = page
    }

    const { pushNotification } = useContext(NotificationContext)
    const [loading, setLoading] = useState(true)
    const [listParams, setListParams] = useState(defaultParams)
    const [data, setData] = useState([])
    const [verificationStatus, setVerificationStatus] = useState(null)
    async function fetchVerificationApplicantList () {
        try {
            setLoading(true)

            const dummyResponseIncomeAndKYC = [
                {
                    ekyc: {
                        status: 'pending',
                        failed_reason: '',
                        similarity: 0
                    },
                    income: {
                        status: 'pending',
                        result_message: ''
                    }
                },
                {
                    ekyc: {
                        status: 'success',
                        failed_reason: '',
                        similarity: 80
                    },
                    income: {
                        status: 'success',
                        result_message: 'kurang lebih 20 s/d 30JT di PT ABC'
                    }
                },
                {
                    ekyc: {
                        status: 'failed',
                        failed_reason: 'KTP tidak sesuai',
                        similarity: 0
                    },
                    income: {
                        status: 'failed',
                        result_message: ''
                    }
                }
            ]
            const { data: responseData } = await Axios.get(GetApplicantList())
            const { data: responseDataVerificationStatus } = await Axios.get(GetApplicantVerificationStatus())

            const data = {
                data: responseData.data && responseData.data.length
                    ? responseData.data.map((val, index) => {
                        return {
                            ...val,
                            ...dummyResponseIncomeAndKYC[index > 2 ? 0 : index]
                        }
                    })
                    : [],
                meta: responseData.meta
            }
            setData(data)
            setVerificationStatus({
                kyc: responseDataVerificationStatus.data?.find((val) => val.includes('kyc')),
                income: responseDataVerificationStatus.data?.find((val) => val.includes('income')),
                family_certificate: responseDataVerificationStatus.data?.find((val) => val.includes('family_certificate'))
            })
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const pushEvent = useAnalyticsPushEvent()

    // Show income.
    const [showModalIncomeVerification, setShowModalIncomeVerification] = useState(false)
    const [selectedShowIncomeVerification, setSelectedShowIncomeVerification] = useState(null)

    // Delete applicate.
    const [showModalDeleteApplicant, setShowModalDeleteApplicant] = useState(false)
    const [selectedDeletedApplicant, setSelectedDeletedApplicant] = useState(null)

    // Show ekyc.
    const [showModalEKYCProcess, setShowModalEKYCProcess] = useState(false)
    const [selectedEKYCProcess, setSelectedEKYCProcess] = useState(null)

    // Show family certificate
    const [showModalFamilyCertificateProcess, setShowModalFamilyCertificateProcess] = useState(false)
    const [selectedFamilyCertificateProcess, setSelectedFamilyCertificateProcess] = useState(null)

    const refreshComponentState = () => {
        setSelectedShowIncomeVerification(null)
        setSelectedDeletedApplicant(null)
        setSelectedEKYCProcess(null)
        setSelectedFamilyCertificateProcess(null)
        fetchVerificationApplicantList()
    }

    const onProcessItem = (eventName = '', clickedItem) => {
        refreshComponentState()

        // Adds to amplitude.
        pushEvent(eventName, { clickedItem })
    }

    // Inits.
    useEffect(() => {
        fetchVerificationApplicantList()
    }, [listParams])
    useEffect(() => {
        if (triggerRefreshTable) {
            setListParams((prev) => ({ ...prev, page: 1 }))
        }
    }, [triggerRefreshTable])

    return (
        <>
            <Row>
                <Col>
                    <h2>Verifikasi Pemohon</h2>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <Button variant="primary" as={Link} size="sm" to="/applicant-verification/new">
                        Tambah
                    </Button>
                </Col>
            </Row>
            <div className="box-shadow-container mb-3">
                <h4 className="mb-3">Daftar Pemohon</h4>
                <>
                    <TablePagination
                        disabled={loading}
                        limit={listParams?.limit}
                        page={listParams?.page}
                        meta={data?.meta}
                        onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
                    />
                    <MergeApplicantTableData
                        loading={loading}
                        listParams={listParams} listItems={data?.data}
                        setShowModalIncomeVerification={setShowModalIncomeVerification}
                        setSelectedShowIncomeVerification={setSelectedShowIncomeVerification}
                        setShowModalDeleteApplicant={setShowModalDeleteApplicant}
                        setSelectedDeletedApplicant={setSelectedDeletedApplicant}
                        setShowModalEKYCProcess={setShowModalEKYCProcess}
                        setSelectedEKYCProcess={setSelectedEKYCProcess}
                        setShowModalFamilyCertificateProcess={setShowModalFamilyCertificateProcess}
                        setSelectedFamilyCertificateProcess={setSelectedFamilyCertificateProcess}
                        verificationStatus={verificationStatus}
                    />
                    <TablePagination
                        disabled={loading}
                        limit={listParams?.limit}
                        page={listParams?.page}
                        meta={data?.meta}
                        onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
                    />

                    <MergeApplicantIncomeVerificationModal
                        showModal={showModalIncomeVerification}
                        setShowModal={setShowModalIncomeVerification}
                        selectedItem={selectedShowIncomeVerification}
                        onProcessItem={onProcessItem}>
                    </MergeApplicantIncomeVerificationModal>

                    <MergeApplicantDeleteModal
                        showModal={showModalDeleteApplicant}
                        setShowModal={setShowModalDeleteApplicant}
                        selectedItem={selectedDeletedApplicant}
                        onProcessItem={onProcessItem}>
                    </MergeApplicantDeleteModal>

                    <MergeApplicantEKYCModal
                        showModal={showModalEKYCProcess}
                        setShowModal={setShowModalEKYCProcess}
                        selectedItem={selectedEKYCProcess}
                        onProcessItem={onProcessItem}>
                    </MergeApplicantEKYCModal>

                    <MergeApplicantFamilyCertificateModal
                        showModal={showModalFamilyCertificateProcess}
                        setShowModal={setShowModalFamilyCertificateProcess}
                        selectedItem={selectedFamilyCertificateProcess}
                        onProcessItem={onProcessItem}>
                    </MergeApplicantFamilyCertificateModal>
                </>
            </div>
        </>
    )
}

export default App
