import React from 'react'

import ReactPaginate from 'react-paginate'
import { Col, Row } from 'react-bootstrap'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

const App = (props) => {
    const { disabled, className, page, limit, meta, onPageChange } = props
    const navigate = useNavigate()
    const location = useLocation()

    const startDataCount = (((page || 1) - 1) * (limit || 1)) + 1
    const finishDataCount = startDataCount + (meta?.total_data || 0) - 1

    const handlePageChange = ({ selected }) => {
        if (disabled) return

        const newPage = selected + 1
        onPageChange({
            page: newPage
        })

        // Updates query params for browser history.
        const params = new URLSearchParams(location.search)
        params.set('page', newPage)
        const options = {
            pathname: location.pathname,
            search: `?${createSearchParams(params)}`
        }
        navigate(options)
    }

    return (
        <Row className={className}>
            <Col>
                Menampilkan baris {startDataCount} s.d. {finishDataCount} dari {meta?.total_data_all || 0} total data
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
                <div className="pagination">
                    <ReactPaginate
                        breakLabel="..."
                        pageCount={meta?.total_page || 1}
                        pageRangeDisplayed={5}
                        previousLabel="<"
                        nextLabel=">"
                        onPageChange={handlePageChange}
                        forcePage={(page || 1) - 1}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default App
