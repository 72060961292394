import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Spinner, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleXmark,
    faCheckCircle,
    faClock
} from '@fortawesome/free-solid-svg-icons'

import CustomModal from '../../Shared/CustomModal'

import { useAnalyticsPushEvent } from '../../../analytics'
import NotificationContext from '../../../contexts/notification'
import {
    GetApplicantDetail,
    GetApplicantFamilyCertificateDetail,
    PostApplicantFamilyCertificateVerification
} from '../../../utils/api'

import './applicant.css'

function App ({ showModal, setShowModal, selectedItem, onProcessItem }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()

    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [familyCertificateData, setFamilyCertificateData] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleAction = () => {
        if (!selected) return

        async function fetchData () {
            setIsSubmitting(true)
            try {
                const { data: responseData } = await Axios.post(
                    PostApplicantFamilyCertificateVerification(selected.uuid)
                )
                pushNotification('success', responseData.message)
                setShowModal(false)

                if (onProcessItem && typeof onProcessItem === 'function') {
                    onProcessItem('Applicant Family Certificate Process', selected)
                }
            } catch (err) {
                pushNotification('error', null, err)
            } finally {
                setIsSubmitting(false)
            }
        }

        fetchData()
    }

    async function fetchDetail (item) {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(
                GetApplicantDetail(item.uuid)
            )
            setSelected((prev) => ({
                ...prev,
                identity_number: responseData.data?.identity_number,
                name: responseData.data?.name,
                identity_url: responseData.data?.identity_url
            }))
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    async function fetchFamilyCertificateResponseMessage (item) {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(
                GetApplicantFamilyCertificateDetail(item.uuid)
            )
            setFamilyCertificateData(responseData.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        if (!selectedItem) return

        setSelected(selectedItem)

        if (selectedItem.type_modal === 'view_detail') {
            // Gets kyc information from API.
            fetchFamilyCertificateResponseMessage(selectedItem)
        } else {
            // Gets non mask information from API.
            fetchDetail(selectedItem)
        }

        // Adds to amplitude.
        pushEvent('Applicant Family Certificate Process', {
            clickedItem: selectedItem
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title={
                selected?.type_modal === 'view_detail' ? 'Lihat Kartu Keluarga' : 'Pengecekan Kartu Keluarga'
            }
            primaryButtonText={
                selected?.type_modal === 'view_detail' ? '' : 'Konfirmasi'
            }
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            secondaryButtonText="Batal"
            primaryButtonDisabled={isSubmitting || loading}
            secondaryButtonDisabled={isSubmitting}
            size={selected?.type_modal === 'view_detail' ? 'lg' : 'md'}
            loading={isSubmitting}
        >
            {loading && (
                <div className="mt-3 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            {!loading && (
                <>
                    {selected?.type_modal !== 'view_detail'
                        ? (
                            <>
                                Apakah Anda yakin ingin melakukan Pengecekan Kartu Keluarga untuk
                                data berikut?
                                <div className="mt-3">
                                    <Row className="mb-3">
                                        <Col sm="2">
                                            <strong>KTP</strong>
                                        </Col>
                                        <Col sm="10">
                                            <h5>{selected?.identity_number}</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col sm="2">
                                            <strong>Nama</strong>
                                        </Col>
                                        <Col sm="10">
                                            <h5>{selected?.name}</h5>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )
                        : (
                            <></>
                        )}
                    {selected?.type_modal === 'view_detail'
                        ? (
                            <>
                                <div className="p-3 border-0 rounded-3">
                                    <div>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <h5 className="fw-bold">
                                                    {familyCertificateData?.applicant?.name}
                                                </h5>
                                                <p className="text-muted mb-1">
                                                    Nomor KTP :{' '}
                                                    {familyCertificateData?.applicant?.identity_number}
                                                </p>
                                                {familyCertificateData?.family_certificate_status ===
                                                    'verified'
                                                    ? (
                                                        <>
                                                            <p className="mb-1">
                                                                <strong>Email:</strong>{' '}
                                                                {familyCertificateData?.applicant?.email}
                                                            </p>
                                                            <p className="mb-1">
                                                                <strong>Nomor HP:</strong>{' '}
                                                                {familyCertificateData?.applicant?.phone_number}
                                                            </p>
                                                            <p className="mb-1">
                                                                <strong>Nomor KK:</strong>{' '}
                                                                {
                                                                    familyCertificateData?.applicant
                                                                        ?.family_certificate_number
                                                                }
                                                            </p>
                                                        </>
                                                    )
                                                    : (
                                                        <></>
                                                    )}
                                            </div>
                                            <div
                                                className={`alert custom-alert custom-alert-${familyCertificateData?.family_certificate_status}`}
                                                role="alert"
                                            >
                                                {familyCertificateData?.family_certificate_status ===
                                                    'verified' && (
                                                    <>
                                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                                        <span className="ms-2">Selesai</span>
                                                    </>
                                                )}
                                                {familyCertificateData?.family_certificate_status ===
                                                    'not_verified' && (
                                                    <>
                                                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                                        <span className="ms-2">Tidak Ditemukan</span>
                                                    </>
                                                )}
                                                {familyCertificateData?.family_certificate_status ===
                                                    'pending' && (
                                                    <>
                                                        <FontAwesomeIcon icon={faClock} size="lg" />
                                                        <span className="ms-2">Menunggu</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {familyCertificateData?.family_certificate_status ===
                                            'verified' && (
                                            <>
                                                <Table responsive className="mt-3">
                                                    <thead>
                                                        <tr className="text-muted">
                                                            <th>NAMA</th>
                                                            <th>KETERANGAN</th>
                                                            <th>NOMOR HP</th>
                                                            <th>EMAIL</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {familyCertificateData?.family_certificate_member?.map(
                                                            (item) => (
                                                                <tr key={item.uuid}>
                                                                    <td className="fw-bold">{item.name}</td>
                                                                    <td>{item.relationship}</td>
                                                                    <td>{item.phone_number}</td>
                                                                    <td>{item.email}</td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <></>
                        )}
                </>
            )}
        </CustomModal>
    )
}

export default App
