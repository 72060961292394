import Axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, Spinner, Tab, Tabs } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import Step3 from './MergeStatementProcessStep3BuyerModal'
import Step1 from './MergeStatementProcessStep1WinDresModal'
import Step2 from './MergeStatementProcessStep2SupplierModal'
import StatementContainer from './StatementContainer'
import CustomModal from '../Shared/CustomModal'
import StatementUpload from '../Shared/Upload'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import {
    GetStatementList,
    PostStatementPreprocess,
    PostStatementUpload,
    PostMergeStatementAnalyze,
    PutMergeStatementDashboardStatus
} from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    // Computed values.
    const hasNotBeenProcessed = () =>
        (selectedItem && !selectedItem.processed_at) ||
        process.env.REACT_APP_MODE_ENV !== 'production'

    // Tabs.
    const tabSelected = (key) => {
        // Adds to amplitude.
        pushEvent(`Change Statement Folder to ${key}`, { clickedItem: selected })
    }

    // Gets detail statement.
    const [detailStatements, setDetailStatements] = useState([])
    async function fetchFilesStatement (item) {
        if (!item) return

        try {
            setLoading(true)
            const params = { merge_uuid: item.uuid }
            const { data: responseData } = await Axios.get(GetStatementList(), { params })
            setDetailStatements(responseData?.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Upload.
    const [uploadUrl, setUploadUrl] = useState(null)
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('Statement File picked in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }
    const uploadFileUploaded = (file) => {
        fetchFilesStatement(selectedItem)

        pushEvent('Statement File uploaded in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            folder: {
                uuid: selectedItem.uuid
            }
        })
    }

    // Processing filters && process.env.REACT_APP_MODE_ENV !== 'production'.
    const listRef = useRef()
    const step1Ref = useRef()
    const step2Ref = useRef()
    const step3Ref = useRef()

    // Process statement.
    const [processing, setProcessing] = useState(false)
    const [selected, setSelected] = useState(null)
    const [uploading, setUploading] = useState(false)

    const executePreprocessStatement = async ({
        currentStatement,
        statementPasswords
    }) => {
        try {
            const password = statementPasswords?.[currentStatement.uuid]

            // Preprocess.
            const url0 = PostStatementPreprocess(currentStatement.uuid)
            const preprocessPayload = {
                password
            }
            await Axios.post(url0, preprocessPayload)
        } catch (err) {
            if (!['STPPS45', 'STPPS46'].includes(err?.response?.data?.error_code)) {
                pushNotification('error', null, err)
            } else {
                await listRef.current.setStatementErrorFilesMessageTable({
                    uuid: currentStatement.uuid,
                    message: err?.response?.data?.message,
                    errorCode: err?.response?.data?.error_code
                })
            }
        }
    }

    const executeMergeStatementAnalyze = async ({
        uuid,
        windowDressingFilters,
        supplierFilter,
        buyerFilters
    }) => {
        try {
            // Preprocess.
            const url0 = PostMergeStatementAnalyze(uuid)
            const analyzePayload = {
                windowDressingFilters,
                supplierFilter,
                buyerFilters
            }
            await Axios.post(url0, analyzePayload)
        } catch (err) {
            pushNotification('error', null, err)
        }
    }

    const handleAction = async () => {
        if (!detailStatements?.length) return

        async function fetchData () {
            try {
                const progressReference = {
                    isBackgroundProcessActive: true,
                    progress: 0,
                    currentBackgroundProcessCounter: 0,
                    maxProgressCounter: 100
                }

                // Defines important variables.
                const listStatements = detailStatements
                const windowDressingFilters = (step1Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const supplierFilters = (step2Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const buyerFilters = (step3Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const statementPasswords = listRef.current.getStatementPasswords()

                // Resets values globally before starting.
                listRef.current.setStatementErrorFilesMessageTable(null, true)
                setProcessing(true)

                // Executes sequentially.
                const results = []
                await listStatements.reduce(async (p, value) => {
                    await p
                    const [result] = await Promise.all([
                        executePreprocessStatement({
                            currentStatement: value,
                            statementPasswords,
                            progressReference
                        })
                    ])

                    results.push(result)
                    return result
                }, Promise.resolve())

                const listStatementErrorFiles = listRef.current.getStatementErrorFiles()

                if (!listStatementErrorFiles.length) {
                    // Post Action each step.
                    step1Ref.current.postAction(windowDressingFilters)
                    step2Ref.current.postAction(supplierFilters)
                    step3Ref.current.postAction(buyerFilters)

                    await executeMergeStatementAnalyze({
                        uuid: selectedItem.uuid, windowDressingFilters, supplierFilters, buyerFilters
                    })
                    await Axios.put(PutMergeStatementDashboardStatus(selectedItem.uuid))

                    // Resets values globally after ending.
                    setProcessing(false)
                    setShowModal(false)

                    navigate(`/statement/detail/${selectedItem.uuid}`)
                } else {
                    setProcessing(false)
                }
            } catch (err) {
                pushNotification('error', null, err)
                setProcessing(false)
            }
        }

        try {
            await fetchData()
        } catch (err) {
            pushNotification('error', null, err)
        }
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setUploadUrl(null)
        setDetailStatements([])

        if (!selectedItem) return

        fetchFilesStatement(selectedItem)
        setUploadUrl(PostStatementUpload(selectedItem.uuid))
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Preprocess Statement Folder', {
            folder: {
                uuid: selectedItem.uuid
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Proses Rekening Koran (Produktif)"
            primaryButtonText={hasNotBeenProcessed() ? 'Proses' : ''}
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            primaryButtonDisabled={!detailStatements?.length}
            secondaryButtonText="Tutup"
            secondaryButtonDisabled={processing || uploading}
            loading={processing || uploading}
            keyboard={!processing && !uploading}
            closeButton={!processing && !uploading}>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && <>
                <Card>
                    <Card.Header>Daftar Rekening Koran</Card.Header>
                    <Card.Body>
                        {
                            !processing &&
                                (<StatementUpload
                                    uploadUrl={uploadUrl}
                                    filePicked={uploadFilePicked}
                                    fileUploaded={uploadFileUploaded}
                                    setUploading={setUploading}
                                    params={{ type: 'sme' }}
                                    showNotes
                                />)
                        }
                        <StatementContainer
                            ref={listRef}
                            mergeStatement={selectedItem}
                            statements={detailStatements}
                            fetchFilesStatement={fetchFilesStatement}
                            processing={processing || uploading} />
                    </Card.Body>
                </Card>
            </>}
            {
                hasNotBeenProcessed() &&
                (<Card className="mt-3">
                    <Card.Header>Kata Kunci</Card.Header>
                    <Card.Body>
                        <Tabs
                            defaultActiveKey="window-dressing" onSelect={tabSelected}
                            className="mb-3">
                            <Tab eventKey="window-dressing" title="Window Dressing">
                                <Step1 ref={step1Ref} selected={selected} processing={processing}></Step1>
                            </Tab>
                            <Tab eventKey="supplier" title="Supplier">
                                <Step2 ref={step2Ref} selected={selected} processing={processing}></Step2>
                            </Tab>
                            <Tab eventKey="buyer" title="Buyer">
                                <Step3 ref={step3Ref} selected={selected} processing={processing}></Step3>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>)
            }

        </CustomModal>
    )
}

export default App
