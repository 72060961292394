import React from 'react'

import { Button, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash, faMoneyBill1Wave, faEye, faCircleXmark, faCheckCircle, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const navigate = useNavigate()
    const {
        loading,
        listParams, listItems,
        setShowModalIncomeVerification,
        setSelectedShowIncomeVerification,
        setShowModalDeleteApplicant,
        setSelectedDeletedApplicant,
        setShowModalEKYCProcess,
        setSelectedEKYCProcess,
        setShowModalFamilyCertificateProcess,
        setSelectedFamilyCertificateProcess,
        verificationStatus
    } = props

    const onShowIncomeVerification = (item, typeModal = '') => {
        setSelectedShowIncomeVerification({ ...item, type_modal: typeModal })
        setShowModalIncomeVerification(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedApplicant({ ...item })
        setShowModalDeleteApplicant(true)
    }

    const onEKYCProcess = (item, typeModal = '') => {
        setSelectedEKYCProcess({ ...item, type_modal: typeModal })
        setShowModalEKYCProcess(true)
    }

    const onShowFamilyCertificateVerification = (item, typeModal = '') => {
        setSelectedFamilyCertificateProcess({ ...item, type_modal: typeModal })
        setShowModalFamilyCertificateProcess(true)
    }

    return (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th style={{ width: '100px' }}>No KTP</th>
                    <th>Nama</th>
                    <th style={{ width: '100px' }}>Dibuat</th>
                    <th colSpan={5} className="text-center">Tindakan</th>
                </tr>
            </thead>
            { loading && (
                <tbody>
                    <tr>
                        <td colSpan="100" className="text-center p-4">
                            <Spinner animation="border" variant="primary" />
                        </td>
                    </tr>
                </tbody>
            )}
            { !loading && (
                <tbody>
                    {listItems?.map((item, index) => (
                        <tr key={item.uuid}>
                            <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                            <td>{item.identity_number}</td>
                            <td>{item.name}</td>
                            <td>{item.created_at}</td>
                            <td style={{ width: '140px' }} className="text-center">
                                <Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => navigate(`/applicant-verification/information/${item.uuid}`)}>
                                    <FontAwesomeIcon icon={faEye} size="lg" />
                                    <p className="m-1 mb-0">Lihat Pemohon</p>
                                </Button>
                            </td>
                            {
                                verificationStatus && verificationStatus.kyc
                                    ? <td style={{ width: '90px' }} className="text-center">
                                        {
                                            item.kyc_status === 'none'
                                                ? <Button size="sm" variant="link" className="text-decoration-none"
                                                    onClick={() => onEKYCProcess(item)}>
                                                    <FontAwesomeIcon icon={faFile} size="lg" />
                                                    <p className="m-1 mb-0">E-KYC</p>
                                                </Button>
                                                : <></>
                                        }
                                        {
                                            item.kyc_status === 'verified'
                                                ? <Button size="sm" variant="link" className="text-success text-decoration-none"
                                                    onClick={() => onEKYCProcess(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                                    <p className="m-1 mb-0">E-KYC</p>
                                                </Button>
                                                : <></>
                                        }
                                        {
                                            item.kyc_status === 'not_verified'
                                                ? <Button size="sm" variant="link" className="text-danger text-decoration-none"
                                                    onClick={() => onEKYCProcess(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                                    <p className="m-1 mb-0">E-KYC</p>
                                                </Button>
                                                : <></>
                                        }
                                    </td>
                                    : null
                            }
                            {
                                verificationStatus && verificationStatus.income
                                    ? <td style={{ width: '1%' }} className="text-center">
                                        {
                                            item.income_status === 'none'
                                                ? <Button size="sm" variant="link" className="text-decoration-none"
                                                    onClick={() => onShowIncomeVerification(item)}>
                                                    <FontAwesomeIcon icon={faMoneyBill1Wave} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                                </Button>
                                                : <></>
                                        }
                                        {
                                            item.income_status === 'verified'
                                                ? <Button size="sm" variant="link" className="text-success text-decoration-none"
                                                    onClick={() => onShowIncomeVerification(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                                </Button>
                                                : <></>
                                        }

                                        {
                                            item.income_status === 'not_verified'
                                                ? <Button size="sm" variant="link" className="text-danger text-decoration-none"
                                                    onClick={() => onShowIncomeVerification(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                                </Button>
                                                : <></>
                                        }
                                    </td>
                                    : null
                            }
                            {
                                verificationStatus && verificationStatus.family_certificate
                                    ? <td style={{ width: '1%' }} className="text-center">
                                        {
                                            item.family_certificate_status === 'none'
                                                ? <Button size="sm" variant="link" className="text-decoration-none"
                                                    onClick={() => onShowFamilyCertificateVerification(item)}>
                                                    <FontAwesomeIcon icon={faPeopleGroup} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Kartu Keluarga</p>
                                                </Button>
                                                : <></>
                                        }
                                        {
                                            item.family_certificate_status === 'pending'
                                                ? <Button size="sm" variant="link" className="text-decoration-none"
                                                    onClick={() => onShowFamilyCertificateVerification(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faPeopleGroup} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Kartu Keluarga</p>
                                                </Button>
                                                : <></>
                                        }
                                        {
                                            item.family_certificate_status === 'verified'
                                                ? <Button size="sm" variant="link" className="text-success text-decoration-none"
                                                    onClick={() => onShowFamilyCertificateVerification(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Kartu Keluarga</p>
                                                </Button>
                                                : <></>
                                        }

                                        {
                                            item.family_certificate_status === 'not_verified'
                                                ? <Button size="sm" variant="link" className="text-danger text-decoration-none"
                                                    onClick={() => onShowFamilyCertificateVerification(item, 'view_detail')}>
                                                    <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                                    <p className="m-1 mb-0">Verifikasi Kartu Keluarga</p>
                                                </Button>
                                                : <></>
                                        }
                                    </td>
                                    : null
                            }
                            <td style={{ width: '1%' }} className="text-center">
                                <Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => onDeleteClicked(item)}>
                                    <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                    <p className="m-1 mb-0">Hapus</p>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            )}
        </Table >
    )
}

export default App
