import React from 'react'

import { Row, Col, Spinner, Badge } from 'react-bootstrap'

const statementType = (type) => {
    let bg = ''
    let text = ''

    if (type === 'sme') {
        bg = 'primary'
        text = 'Produktif'
    } else {
        bg = 'danger'
        text = 'Konsumtif'
    }

    return (
        <h5>
            <Badge bg={bg} pill>
                {text}
            </Badge>
        </h5>
    )
}

const App = (props) => {
    const { type, mergeStatement, loading } = props

    return (
        <div className='box-shadow-container mb-4'>
            <Row className="mb-3">
                <Col>
                    <h4 className="mb-4">Detil Informasi</h4>
                </Col>
            </Row>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && (
                <>
                    <Row className="mb-2">
                        <Col md={2}>
                            <p className='fw-bold fs-16'>Nama File</p>
                        </Col>
                        <Col md={4} className="text-left">
                            <p className='fs-16'>{mergeStatement?.file_name}</p>
                        </Col>
                        <Col md={2}>
                            <p className='fw-bold fs-16'>Tanggal Dibuat</p>
                        </Col>
                        <Col md={4} className="text-left">
                            <p className='fs-16'>{mergeStatement?.created_at}</p>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={2}>
                            <p className='fw-bold fs-16'>Tipe</p>
                        </Col>
                        <Col md={4} className="text-left">
                            {statementType(type)}
                        </Col>
                        <Col md={2}>
                            <p className='fw-bold fs-16'>Tanggal Diproses</p>
                        </Col>
                        <Col md={4} className="text-left">
                            <p className='fs-16'>{mergeStatement?.processed_at}</p>
                        </Col>
                    </Row>
                </>
            ) }
        </div>
    )
}

export default App
