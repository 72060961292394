import React from 'react'
import Lottie from 'react-lottie'

const LottieAnimation = ({ animationData }) => {
    // Set up Lottie options
    const options = {
        animationData,
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <div>
            <Lottie options={options} height={200} width={400} />
        </div>
    )
}

export default LottieAnimation
