import React from 'react'
import MonthlyData from './MonthlyData'
import { PostTrailMonthlyData } from '../../utils/api'

const App = () => {
    const postDataUrlFn = PostTrailMonthlyData
    const pushEventMessage = 'Trail Download Click'
    const getExcelSettings = () => {
        return {
            responseDataTransformer: x => ({
                'Nama Klien': x.company_name,
                Pengguna: x.user,
                Tindakan: x.description,
                Tipe: x.type,
                File: x.file_name,
                Waktu: x.created_at
            }),
            excelColsConfigs: [{ wch: 25 }, { wch: 25 }, { wch: 20 }, { wch: 17 }, { wch: 45 }, { wch: 18 }]
        }
    }

    return (
        <MonthlyData postDataUrlFn={postDataUrlFn}
            pushEventMessage={pushEventMessage}
            getExcelSettings={getExcelSettings} />
    )
}

export default App
