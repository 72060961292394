import React from 'react'

import { Button, Table, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCog,
    faExternalLink as ShowResultIcon,
    faTriangleExclamation as UnprocessedIcon,
    faTrash
} from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const navigate = useNavigate()
    const {
        loading,
        mergeStatementData,
        listParams, listItems,
        setShowModalProcessedStatement,
        setSelectedProcessedStatement,
        setShowModalDeletedStatement,
        setSelectedDeletedStatement
    } = props

    const onShowResultClicked = (item) => {
        navigate(`/consumptive-statement/detail/${item.uuid}`)
    }

    const onProcessClicked = (item) => {
        setSelectedProcessedStatement({ ...item })
        setShowModalProcessedStatement(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedStatement({ ...item })
        setShowModalDeletedStatement(true)
    }

    return (
        <Table responsive striped hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th>File</th>
                    <th style={{ width: '30%' }}>Rekening</th>
                    <th style={{ width: '100px', minWidth: '80px' }} className="text-center">Hasil</th>
                    <th style={{ width: '100px', minWidth: '100px' }}>Dibuat</th>
                    <th style={{ width: '100px', minWidth: '100px' }}>Diproses</th>
                    {
                        mergeStatementData && mergeStatementData.meta && mergeStatementData.meta.sca
                            ? <th style={{ width: '100px', minWidth: '100px' }}>Selesai</th>
                            : null
                    }
                    <th style={{ width: '1%' }} colSpan={3} className="text-center">Tindakan</th>
                </tr>
            </thead>
            { loading && (
                <tbody>
                    <tr>
                        <td colSpan="100" className="text-center p-4">
                            <Spinner animation="border" variant="primary" />
                        </td>
                    </tr>
                </tbody>
            )}
            { !loading && (
                <tbody>
                    {listItems?.map((item, index) => (
                        <tr key={item.uuid}>
                            <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                            <td><pre style={{
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>{item.file_name || item.uuid}</pre></td>
                            <td style={{ whiteSpace: 'break-spaces' }}>
                                {
                                    item && item.bank_statement_detail && ['completed', 'unprocessed'].includes(item.analysis_status)
                                        ? item.bank_statement_detail.map((detail, index) => {
                                            return `${index + 1}. ${detail.bank_name} ${detail.bank_account_name} ${detail.bank_account_number} ${detail.period}`
                                        }).join('\n')
                                        : null
                                }
                            </td>
                            <td style={{ width: '115px' }} className="text-center">
                                {item.analysis_status === 'completed' && (
                                    <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onShowResultClicked(item)}>
                                        <FontAwesomeIcon icon={ShowResultIcon} size="lg" />
                                        <p className="mb-0">Lihat <br /> Hasil</p>
                                    </Button>
                                )}
                                {item.analysis_status === 'in_progress' && (
                                    <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onShowResultClicked(item)}>
                                        <Spinner className="loading-row-table" size="sm" animation="grow" />
                                        <p className="mb-0">Sedang Diproses</p>
                                    </Button>
                                )}
                                {item.analysis_status === 'unprocessed' && (
                                    <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onShowResultClicked(item)}>
                                        <span className="text-dark"><FontAwesomeIcon icon={UnprocessedIcon} size="lg" /></span>
                                        <p className="mb-0">Tidak Tersedia</p>
                                    </Button>
                                )}
                            </td>
                            <td>{item.created_at}</td>
                            <td>{item.processed_at}</td>
                            {
                                mergeStatementData && mergeStatementData.meta && mergeStatementData.meta.sca
                                    ? <td>{item.completed_at}</td>
                                    : null
                            }
                            <td className="text-center">
                                {
                                    (process.env.REACT_APP_MODE_ENV !== 'production') ||
                                    (process.env.REACT_APP_MODE_ENV === 'production' && item.analysis_status === 'pending')
                                        ? (
                                            <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                                onClick={() => onProcessClicked(item)}>
                                                <FontAwesomeIcon icon={faCog} size="lg" />
                                                <p className="m-1">Proses</p>
                                            </Button>
                                        )
                                        : null
                                }
                            </td>
                            <td className="text-center">
                                {
                                    <Button size="sm" variant="link" className="text-decoration-none fw-medium"
                                        onClick={() => onDeleteClicked(item)}>
                                        <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                        <p className="m-1">Hapus</p>
                                    </Button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            )}
        </Table >
    )
}

export default App
