import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import MergeSlikDeleteModal from './MergeSlikDeleteModal'
import MergeSlikProcessModal from './MergeSlikProcessModal'
import MergeSlikShowResultModal from './MergeSlikShowResultModal'
import MergeSlikTableData from './MergeSlikTableData'
import TablePagination from '../Shared/TablePagination'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetMergeSlikList } from '../../utils/api'

function App ({ triggerRefreshTable }) {
    // Gets default value from query params.
    const defaultParams = { page: 1, limit: 20 }
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
        defaultParams.page = page
    }

    const { pushNotification } = useContext(NotificationContext)
    const [loading, setLoading] = useState(true)
    const [listParams, setListParams] = useState(defaultParams)
    const [data, setData] = useState([])
    async function fetchMergeSlik () {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetMergeSlikList(), { params: { ...listParams } })
            setData(responseData)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const pushEvent = useAnalyticsPushEvent()

    // Show result slik.
    const [showModalShowResultSlik, setShowModalShowResultSlik] = useState(false)
    const [selectedShowResultSlik, setSelectedShowResultSlik] = useState(null)

    // Process slik.
    const [showModalProcessedSlik, setShowModalProcessedSlik] = useState(false)
    const [selectedProcessedSlik, setSelectedProcessedSlik] = useState(null)

    // Delete slik.
    const [showModalDeletedSlik, setShowModalDeletedSlik] = useState(false)
    const [selectedDeletedSlik, setSelectedDeletedSlik] = useState(null)

    const onDeleted = (deleted) => {
        setSelectedShowResultSlik(null)
        setSelectedProcessedSlik(null)
        setSelectedDeletedSlik(null)
        fetchMergeSlik()

        // Adds to amplitude.
        pushEvent('Slik Folder deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        fetchMergeSlik()
    }, [listParams])
    useEffect(() => {
        if (triggerRefreshTable) {
            setListParams((prev) => ({ ...prev, page: 1 }))
        }
    }, [triggerRefreshTable])

    return (
        <>
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />
            <MergeSlikTableData
                loading={loading}
                listParams={listParams} listItems={data?.data}
                setShowModalShowResultSlik={setShowModalShowResultSlik}
                setSelectedShowResultSlik={setSelectedShowResultSlik}
                setShowModalProcessedSlik={setShowModalProcessedSlik}
                setSelectedProcessedSlik={setSelectedProcessedSlik}
                setShowModalDeletedSlik={setShowModalDeletedSlik}
                setSelectedDeletedSlik={setSelectedDeletedSlik}
            />
            <TablePagination
                disabled={loading}
                limit={listParams?.limit}
                page={listParams?.page}
                meta={data?.meta}
                onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
            />

            <MergeSlikShowResultModal
                showModal={showModalShowResultSlik}
                setShowModal={setShowModalShowResultSlik}
                selectedItem={selectedShowResultSlik}>
            </MergeSlikShowResultModal>

            <MergeSlikProcessModal
                showModal={showModalProcessedSlik}
                setShowModal={setShowModalProcessedSlik}
                selectedItem={selectedProcessedSlik}
                onProcessCompleted={fetchMergeSlik}>
            </MergeSlikProcessModal>

            <MergeSlikDeleteModal
                showModal={showModalDeletedSlik}
                setShowModal={setShowModalDeletedSlik}
                selectedItem={selectedDeletedSlik}
                onDeleted={onDeleted}>
            </MergeSlikDeleteModal>
        </>
    )
}

export default App
