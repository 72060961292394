import React from 'react'

import { Row, Col, Spinner, Image } from 'react-bootstrap'

import StatementDetailProgressBar from './StatementDetailProgressBar'
import TitledFiles from '../Shared/TitledFiles'

import './statement.css'

const App = (props) => {
    const {
        mergeStatement,
        loading
    } = props

    return (
        <div className='box-shadow-container mb-4'>
            <Row className="mb-3">
                <Col>
                    <h4>File</h4>
                </Col>
            </Row>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && (
                <>
                    { mergeStatement?.analysis_status === 'unprocessed' && (
                        <Row className='text-center'>
                            <Col md={{ span: 6, offset: 3 }}>
                                <Image src="/img/not_processed.png" fluid className="object-fit-cover loading-inprogess-statement" />
                            </Col>
                            <Col md={12}>
                                <h5>Rekening koran tidak berhasil diproses</h5>
                            </Col>
                        </Row>
                    )}
                    { ['none', 'in_progress', 'pending'].includes(mergeStatement?.analysis_status) && (
                        <>
                            <StatementDetailProgressBar mergeStatement={mergeStatement} />
                        </>
                    )}
                    { mergeStatement?.analysis_status === 'completed' && (
                        <TitledFiles
                            parent="Statement"
                            selected={mergeStatement}
                            files={mergeStatement?.processed_files}
                            className='m-0'
                            borderClassName='border-secondary-subtle'
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default App
