import { useContext, useEffect } from 'react'

import SocketContext from '../../contexts/socket'

export const useSocketHandlers = (props) => {
    const {
        mergeStatement,
        setMergeStatement,
        fetchFilesStatement
    } = props

    const { subscribe, unsubscribe, events } = useContext(SocketContext)

    // Socket handler.
    const notificationDocumentCompletedHandler = async (data, currentMs) => {
        try {
            if (data?.doc_type !== 'bs') return
            if (data?.doc?.uuid !== currentMs?.uuid) return
            // console.log('useSocketHandlers notificationDocumentCompletedHandler', new Date())

            fetchFilesStatement(false)
        } catch (err) {
            console.log(err)
        }
    }

    // Socket handler.
    const notificationStatementFileCompletedHandler = async (data, currentMs) => {
        try {
            if (data?.doc_type !== 'bs-file') return
            if (data?.doc?.uuid !== currentMs?.uuid) return
            // console.log('useSocketHandlers notificationStatementFileCompletedHandler', new Date())

            // No need to fetch to the server to reduce latency.
            const infos = [data?.info?.total_all_statements, data?.info?.total_processed_statements, data?.info?.total_unprocessed_statements]
            if (infos.every(i => i !== undefined)) {
                setMergeStatement(prev => ({
                    ...prev,
                    total_all_statements: data.info.total_all_statements,
                    total_processed_statements: data.info.total_processed_statements,
                    total_unprocessed_statements: data.info.total_unprocessed_statements
                }))
            }

            // fetchFilesStatement(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        subscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler, mergeStatement)
        subscribe(events.NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT, notificationStatementFileCompletedHandler, mergeStatement)

        return () => {
            unsubscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler)
            unsubscribe(events.NOTIFICATION_STATEMENT_FILE_COMPLETED_EVENT, notificationStatementFileCompletedHandler)
        }
    }, [mergeStatement])

    return {}
}

export const useAutoRefresh = (props) => {
    const {
        mergeStatement,
        fetchFilesStatement
    } = props

    useEffect(() => {
        if (!mergeStatement) return
        if (mergeStatement.analysis_status === 'completed') return

        const targetTime = new Date(mergeStatement.fine_tuning_estimation_at).getTime()
        const timeDifference = targetTime - Date.now()
        if (timeDifference < 0) return

        const timeoutId = setTimeout(() => {
            // console.log('useAutoRefresh setTimeout', new Date())
            fetchFilesStatement(false)
        }, timeDifference)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [mergeStatement])

    return {}
}
